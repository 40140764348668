import React, { useContext } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import LangContext from "../langContext";
import dict from "../../data/dict";
import IntroContext from "../introContext";

const TabletNav = ({ isOpen, toggleMenu }) => {
	const { lang } = useContext(LangContext);
	const { setIsInit } = useContext(IntroContext);
	const trans = dict[lang].nav;

	const onSidebarClick = ({ target: { className } }) => {
		if (className.includes("language")) return;
		toggleMenu();
		setIsInit(false);
	};

	return (
		<div className={classnames("sidebar-wrap", { open: isOpen })}>
			<div className="sidebar">
				<nav className="mobile-nav">
					<NavLink
						to="about"
						className="mobile-nav__link"
						onClick={onSidebarClick}
						activeClassName="active"
					>
						{trans.about}
					</NavLink>
					<div className="mobile-nav__separator mobile-nav__separator--right" />
					<NavLink
						to="solutions"
						className="mobile-nav__link"
						onClick={onSidebarClick}
						activeClassName="active"
					>
						{trans.solutions}
					</NavLink>
					<div className="mobile-nav__separator mobile-nav__separator--left" />
					<NavLink
						to="contact"
						className="mobile-nav__link"
						onClick={onSidebarClick}
						activeClassName="active"
					>
						{trans.contact}
					</NavLink>
				</nav>
			</div>
		</div>
	);
};

export default TabletNav;
