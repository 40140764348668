import React, { useContext } from "react";
import Container from "../shared/Container";
import Header from "../shared/Header";
import TextSlider from "../shared/TextSlider";
import * as Query from "../shared/Query";
import LangContext from "../langContext";
import dict from "../../data/dict";
import PageAnim from "../shared/PageAnim";

const About = () => {
	const { lang } = useContext(LangContext);
	const trans = dict[lang].about;
	const renderText = text => {
		return text.split(" ").map((text, index) => {
			return (
				<span key={`${text}-${index}`} data-text={text}>
					{text}&nbsp;
				</span>
			);
		});
	};

	return (
		<Container>
			<PageAnim text="ABOUT" />
			<Query.Desktop>
				<TextSlider
					direction="vertical"
					type="about-vertical"
					lang={lang}
				>
					<p className="anim-text">
						{renderText(trans.slider.default)}
					</p>
					<p className="anim-text">
						{renderText(trans.slider.default)}
					</p>
				</TextSlider>
			</Query.Desktop>
			<Query.TabletLandscape>
				<TextSlider
					direction="horizontal"
					type="about-horizontal"
					lang={lang}
				>
					<p className="anim-text">
						{renderText(trans.slider.default)}
					</p>
					<p className="anim-text">
						{renderText(trans.slider.default)}
					</p>
					<p className="anim-text">
						{renderText(trans.slider.default)}
					</p>
					<p className="anim-text">
						{renderText(trans.slider.default)}
					</p>
				</TextSlider>
			</Query.TabletLandscape>

			<div className="about">
				<div className="about-header">
					<Header name="about" title={trans.title} />
				</div>
				<div className="about-content">
					<Query.Mobile>
						<p className="about-content__text">
							{trans.subtitle.default}
						</p>
					</Query.Mobile>
					<Query.TabletPortrait>
						<p className="about-content__text">
							{trans.subtitle.tabletPortrait}
						</p>
					</Query.TabletPortrait>
					<Query.TabletLandscape up>
						<p className="about-content__text">
							{trans.subtitle.default}
						</p>
					</Query.TabletLandscape>
					<p className="about-content__text">{trans.textMiddle}</p>
					<p className="about-content__text">{trans.textBottom}</p>
					<Query.TabletPortrait down>
						<TextSlider
							direction="horizontal"
							type="about-horizontal-mobile"
							lang={lang}
						>
							<p className="anim-text">
								{renderText(trans.slider.top)}
							</p>
							<p className="anim-text">
								{renderText(trans.slider.top)}
							</p>
							<p className="anim-text">
								{renderText(trans.slider.top)}
							</p>
							<p className="anim-text">
								{renderText(trans.slider.top)}
							</p>
						</TextSlider>
						<div className="about-content__mobile-text">
							<p className="anim-text align-right">
								{renderText(trans.slider.bottom)}
							</p>
						</div>
					</Query.TabletPortrait>
				</div>
			</div>
		</Container>
	);
};

export default About;
