import React, { useState, useRef, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home/Home.js";
import About from "./about/About.js";
import Solutions from "./solutions/Solutions.js";
import Rodo from "./rodo/Rodo.js";
import Contact from "./contactPage/ContactPage";
import * as Query from "./shared/Query";
import LangContext from "./langContext";
import IntroContext from "./introContext";

const App = () => {
	const langFromLocalStorage = localStorage.getItem("language");
	const [lang, setLanguage] = useState(langFromLocalStorage || "en");
	const [showElements, setShowElements] = useState(false);

	const [isVideo, hideVideo] = useState(true);
	const [isInit, setIsInit] = useState(true);

	const videoRef = useRef();

	const setLang = lang => {
		localStorage.setItem("language", lang);
		setLanguage(lang);
	};

	const valueLangContext = {
		lang,
		setLang
	};

	const valueIntroContext = {
		showElements,
		showLang: !isVideo,
		isInit,
		setIsInit
	};

	useLayoutEffect(() => {
		const video = videoRef.current;
		video.addEventListener("ended", hideVideoPlayer);
		video.addEventListener("timeupdate", () => handleVideoTime(video));
		return () => {
			// video.removeEventListener("ended", hideVideoPlayer);
			video.removeEventListener("timeupdate", handleVideoTime);
		};
	}, []);

	const hideVideoPlayer = () => {
		hideVideo(false);
		setShowElements(true);
	};

	const handleVideoTime = video => {
		if (video.currentTime > 10) {
			setShowElements(true);
		}
	};

	const isPortrait = window.innerHeight > window.innerWidth;

	const videoSource = isPortrait
		? "https://problem-solved.s3.amazonaws.com/problem_solved_intro_mobile.mp4"
		: "https://problem-solved.s3.amazonaws.com/problem_sloved_intro.mp4";


	return (
		<IntroContext.Provider value={valueIntroContext}>
			<LangContext.Provider value={valueLangContext}>
				{isVideo && (
					<>
						<div className="init">
							<video
								className="init-video"
								autoPlay
								muted
								playsInline
								ref={videoRef}
								onEnded={hideVideoPlayer}
							>
								<source src={videoSource} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>
						<p className="init-button" onClick={hideVideoPlayer}>
							{lang === "en" ? "Skip" : "Pomiń"}
						</p>
					</>
				)}
				<div className="main-wrapper">
					<Query.TabletLandscape up>
						<Router>
							<Switch>
								<Route path="/" exact component={Home} />
								<Route path="/about" component={About} />
								<Route
									path="/solutions"
									component={Solutions}
								/>
								<Route path="/contact" component={Contact} />
								<Route path="/rodo" component={Rodo} />
							</Switch>
						</Router>
					</Query.TabletLandscape>
					<Query.TabletPortrait down>
						<Router>
							<Switch>
								<Route path="/rodo" component={Rodo} />
								<Route path="/" component={MobileApp} />
							</Switch>
						</Router>
					</Query.TabletPortrait>
				</div>
			</LangContext.Provider>
		</IntroContext.Provider>
	);
};

const MobileApp = () => {
	return (
		<div>
			<Home />
			<About />
			<Solutions />
			<Contact />
		</div>
	);
};

export default App;
