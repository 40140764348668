import React, { useContext } from "react";
import LangContext from "../langContext";

const Lang = ({ isMobile, className }) => {
	const { lang, setLang } = useContext(LangContext);
	const setLanguage = () => {
		const langToSet = lang === "pl" ? "en" : "pl";
		setLang(langToSet);
	};

	const trans = {
		en: isMobile ? "English" : "EN",
		pl: isMobile ? "Polski" : "PL"
	};

	return (
		<div className={className} onClick={setLanguage}>
			{lang === "pl" ? trans.en : trans.pl}
		</div>
	);
};

export default Lang;
