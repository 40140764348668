import React, { useContext } from "react";
import Container from "../shared/Container";
import Header from "../shared/Header";
import Line from "../shared/Line";
import TextSlider from "../shared/TextSlider";
import * as Query from "../shared/Query";
import LangContext from "../langContext";
import dict from "../../data/dict";
import PageAnim from "../shared/PageAnim";

const Solutions = () => {
	const { lang } = useContext(LangContext);
	const trans = dict[lang].solutions;

	const renderText = text => {
		return text.split(" ").map((text, index) => {
			return (
				<span key={`${text}-${index}`} data-text={text}>
					{text}&nbsp;
				</span>
			);
		});
	};

	return (
		<Container scroll>
			<PageAnim text="SOLUTIONS" />
			<div className="solutions">
				<div className="solutions-top">
					<div className="solutions-top__header">
						<Header name="solutions" title={trans.title} />
					</div>
					<div className="solutions-top__aside">
						<Query.Desktop>
							<p className="solutions-top__aside-text">
								{trans.subtitle.default}
							</p>
						</Query.Desktop>
						<Line type="big" text={trans.line} />
					</div>
				</div>
				<div className="solutions-middle">
					<Query.TabletLandscape>
						<p className="solutions-middle__text absolute">
							{trans.subtitle.tabletLandscape}
						</p>
					</Query.TabletLandscape>
					<Query.TabletPortrait down>
						<p className="solutions-middle__text absolute">
							{trans.subtitle.mobile}
						</p>
						<p className="solutions-middle__text">
							{trans.textTop.mobile}
						</p>
						<p className="solutions-middle__text">
							{trans.textMiddle.mobile}
						</p>
					</Query.TabletPortrait>
					<Query.TabletLandscape up>
						<p className="solutions-middle__text">
							{trans.textTop.default}
						</p>
						<p className="solutions-middle__text">
							{trans.textMiddle.default}
						</p>
					</Query.TabletLandscape>
				</div>
				<Line
					type="small"
					dotPosition="left"
					className="solutions__line"
				/>
				<TextSlider
					direction="horizontal"
					type="solutions-horizontal"
					lang={lang}
				>
					<p className="anim-text">{renderText(trans.slider)}</p>
					<p className="anim-text">{renderText(trans.slider)}</p>
					<p className="anim-text">{renderText(trans.slider)}</p>
					<p className="anim-text">{renderText(trans.slider)}</p>
					<p className="anim-text">{renderText(trans.slider)}</p>
					<p className="anim-text">{renderText(trans.slider)}</p>
				</TextSlider>
				<div className="solutions-bottom">
					<p className="solutions-bottom__text">{trans.textBottom}</p>
					<h2 className="solutions-bottom__anim-text">
						{renderText(trans.textAnim)}
					</h2>
				</div>
			</div>
		</Container>
	);
};

export default Solutions;
