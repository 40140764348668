import React from "react";
import Responsive from "react-responsive";

export const Mobile = props => <Responsive {...props} maxWidth={600} />;
export const TabletPortrait = props => (
	<Responsive
		{...props}
		minWidth={props.down ? null : 601}
		maxWidth={props.up ? null : 900}
	/>
);
export const TabletLandscape = props => (
	<Responsive
		{...props}
		minWidth={props.down ? null : 901}
		maxWidth={props.up ? null : 1200}
	/>
);
export const Desktop = props => <Responsive {...props} minWidth={1201} />;
