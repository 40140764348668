import React from "react";
import Container from "../shared/Container";
import Header from "../shared/Header";
import PageAnim from "../shared/PageAnim";

const Rodo = () => {
	return (
		<Container rodo scroll>
			<PageAnim text="RODO" />
			<div className="rodo">
				<div className="rodo-header">
					<Header name="rodo" title="rodo" />
				</div>
				<div className="rodo-content">
					<h3 className="rodo-content__subtitle">
						The RODO information clause on the processing of
						personal data
					</h3>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(1)</span>{" "}
						The controller of personal data shall be <br />
						Problem Solved Social LTD
						<br />
						8-9 MARINO MART FAIRVIEW DUBLIN 3<br />
						D03P590 Dublin
						<br />
						Ireland
						<br />
						Company registraion number: 645122
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(2)</span>{" "}
						The controller shall appoint a personal data protection
						officer. Contact details of the DPO: M. Sikorski
						rodo(at)problemsolved.social , telephone: +353 1 443
						3066;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(3)</span>{" "}
						The personal data provided will be processed for the
						purpose of providing services, handling applications and
						responding to applications;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(4)</span>{" "}
						Categories of personal data include, among others, name
						and surname, telephone number, e-mail address, address,
						data dedicated to the process/service/project;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(5)</span>{" "}
						Your personal data may be transferred to entities
						processing personal data at the request of the
						administrator: they will not.
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(6)</span>{" "}
						Your personal data will be stored for the duration of
						the existence of a legitimate interest of the
						controller, unless you object to the processing of the
						data;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(7)</span>{" "}
						Your data will not be transferred to a third country or
						an international organisation;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(8)</span>{" "}
						You have the right to access the content of your data
						and the right to rectify, erase, restrict, limit,
						transfer, object, withdraw consent at any time without
						affecting the lawfulness of the processing carried out
						on the basis of consent prior to its withdrawal;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(9)</span>{" "}
						You have the right to lodge a complaint with the
						supervisory authority responsible for personal data
						protection, which is the President of the Office for
						Personal Data Protection, if you consider that the
						processing of your personal data violates the provisions
						of the Act of 10 May 2018 on Personal Data Protection
						(uniform text, Journal of Laws of 2018, item 1000) or
						the provisions of Regulation (EU) 2016/679 of the
						European Parliament and of the Council of 27 April 2016
						on the protection of individuals with regard to the
						processing of personal data and on the free movement of
						such data and repealing Directive 95/46/EC (general
						regulation on data protection) of 27 April 2016.
						(Official Journal of the European Union No. 119, p. 1);
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(10)</span>{" "}
						The data you provide will not be subject to automated
						decision making and profiling;
					</p>
					<p className="rodo-content__text">
						<span className="rodo-content__text--bold">(11)</span>{" "}
						Data comes from data subjects;
					</p>
					<p className="rodo-content__text">
						The provision of personal data by you is voluntary;
					</p>
				</div>
			</div>
		</Container>
	);
};

export default Rodo;
