import React, { useContext } from "react";
import classnames from "classnames";
import Lang from "./Lang";
import LangContext from "../langContext";
import dict from "../../data/dict";

const MobileNav = ({ isOpen, toggleMenu }) => {
	const { lang } = useContext(LangContext);
	const trans = dict[lang].nav;

	const onSidebarClick = ({ target: { className } }, query) => {
		if (className.includes("language")) return;
		const element = document.querySelector(query);
		element.scrollIntoView();
		toggleMenu();
	};
	return (
		<div className={classnames("sidebar-wrap", { open: isOpen })}>
			<div className="sidebar">
				<nav className="mobile-nav">
					{/* <div
						className="mobile-nav__link"
						onClick={e => onSidebarClick(e, "#root")}
					>
						Home
					</div>
					<div className="mobile-nav__separator mobile-nav__separator--right" /> */}
					<div
						className="mobile-nav__link"
						onClick={e => onSidebarClick(e, ".about")}
					>
						{trans.about}
					</div>
					<div className="mobile-nav__separator mobile-nav__separator--right" />
					<div
						className="mobile-nav__link"
						onClick={e => onSidebarClick(e, ".solutions")}
					>
						{trans.solutions}
					</div>
					<div className="mobile-nav__separator mobile-nav__separator--left" />
					<div
						className="mobile-nav__link"
						onClick={e => onSidebarClick(e, ".contact")}
					>
						{trans.contact}
					</div>
					<div className="mobile-nav__separator mobile-nav__separator--right" />
					<Lang className="mobile-nav__link language" isMobile />
				</nav>
			</div>
		</div>
	);
};

export default MobileNav;
