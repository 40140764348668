import React from "react";
import classnames from "classnames";
import Line from "./Line";

import TAGS from "../../data/tags";

const Header = ({ title, name, className }) => {
	return (
		<header className={`header ${className ? className : ""}`}>
			<h1
				className={classnames(
					"header__title",
					`header__title--${name}`
				)}
			>
				{title.split("").map((text, index) => {
					return <span key={`${text}-${index}`}>{text}</span>;
				})}
			</h1>
			<Line type="small" dotPosition="right" />
			<Line type="big" tags={TAGS[name]} />
		</header>
	);
};

export default Header;
