import React from "react";
import classnames from "classnames";

const TextSlider = ({ direction, children, type, lang }) => {
	return (
		<div
			className={classnames(
				"slider",
				`slider-${direction}`,
				`slider-${type}`
			)}
		>
			<div
				className={classnames(
					"slider-track",
					`slider-track--${type}`,
					`slider-track--${type}-${lang}`
				)}
			>
				{children}
			</div>
		</div>
	);
};

export default TextSlider;
