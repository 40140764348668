import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

const Logo = () => {
	return (
		<div className="logo-wrapper">
			<Link to="/">
				<img
					className="logo-wrapper__logo"
					src={logo}
					alt="problem solved"
				/>
			</Link>
		</div>
	);
};

export default Logo;
