import React, { useContext } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import LangContext from "../langContext";
import IntroContext from "../introContext";
import dict from "../../data/dict";

const Nav = props => {
	const { lang } = useContext(LangContext);
	const { setIsInit } = useContext(IntroContext);
	const trans = dict[lang].nav;
	const disableInitFlag = () => {
		setIsInit(false);
	};

	return (
		<nav className="nav">
			<NavLink
				to="/about"
				className="nav__link"
				activeClassName="active"
				onClick={disableInitFlag}
			>
				{trans.about}
			</NavLink>
			<div className="nav__separator" />
			<NavLink
				to="/solutions"
				className="nav__link"
				activeClassName="active"
				onClick={disableInitFlag}
			>
				{trans.solutions}
			</NavLink>
			<div className="nav__separator" />
			<NavLink
				to="/contact"
				className="nav__link"
				activeClassName="active"
				onClick={disableInitFlag}
			>
				{trans.contact}
			</NavLink>
		</nav>
	);
};

export default withRouter(Nav);
