import React from "react";
import classnames from "classnames";

const Line = ({ type, dotPosition, tags, text, className }) => {
	const renderTags = () => {
		return tags.map(tag => (
			<div className="horizontal-line__tags-item" key={tag}>
				#{tag}
			</div>
		));
	};

	return (
		<div
			className={classnames(
				"horizontal-line",
				`horizontal-line--${type}`,
				`horizontal-line--${dotPosition}`,
				className
			)}
		>
			{tags && (
				<div className="horizontal-line__tags">{renderTags()}</div>
			)}
			{text && <div className="horizontal-line__text">{text}</div>}
		</div>
	);
};

export default Line;
