import React, { useState, useContext } from "react";
import * as Query from "./Query";
import IntroContext from "../introContext";

const PageAnim = ({ text }) => {
	const [isTextAnim, setIsTextAnim] = useState(true);
	const [isMainAnim, setIsMainAnim] = useState(true);
	const { isInit } = useContext(IntroContext);

	const onTextAnimationEnd = () => {
		setIsTextAnim(false);
	};

	const onMainAnimationEnd = () => {
		setIsMainAnim(false);
	};

	return null;

	// return isMainAnim && !isInit ? (
	// 	<Query.TabletLandscape up>
	// 		<div className="anim-wrap">
	// 			<div
	// 				className={`page-anim ${!isTextAnim ? "anim" : ""}`}
	// 				onAnimationEnd={onMainAnimationEnd}
	// 			/>
	// 			{isTextAnim && (
	// 				<h1 onAnimationEnd={onTextAnimationEnd}>
	// 					{text.split().map((letter, i) => {
	// 						return <span key={`${letter}-${i}`}>{letter}</span>;
	// 					})}
	// 				</h1>
	// 			)}
	// 		</div>
	// 	</Query.TabletLandscape>
	// ) : null;
};

export default PageAnim;
