import React, { useContext } from "react";
import Container from "../shared/Container";
import MediaQuery from "react-responsive";
import LangContext from "../langContext";
import dict from "../../data/dict";
import PageAnim from "../shared/PageAnim";

const Home = () => {
	const { lang } = useContext(LangContext);
	const trans = dict[lang].home;

	return (
		<Container isHomepage>
			<PageAnim text="PROBLEM SOLVED" />
			<section className="home">
				<MediaQuery query="(min-width: 901px)">
					<h1 className="home__title">WE ARE THE SOLUTION MAKERS.</h1>
				</MediaQuery>
				<MediaQuery query="(max-width: 900px)">
					<h1 className="home__title">
						WE ARE <br />
						THE SOLUTION
						<br /> MAKERS.
					</h1>
				</MediaQuery>
				<h2 className="home__subtitle">{trans.letsMeet}</h2>
			</section>
		</Container>
	);
};

export default Home;
