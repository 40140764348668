import React, { useState, useContext, useEffect } from "react";
import Logo from "./Logo";
import Nav from "./Nav";
import classnames from "classnames";
import MediaQuery from "react-responsive";
import MobileNav from "./MobileNav";
import Lang from "./Lang";
import * as Query from "./Query";
import TabletNav from "./TabletNav";
import icon from "../../assets/close.png";
import { Link } from "react-router-dom";
import IntroContext from "../introContext";

const Container = ({ children, scroll, isHomepage, rodo }) => {
	const { showLang, showElements } = useContext(IntroContext);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (window.matchMedia("min-width: 901px").matches) {
			document.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const menu = (
		<div
			className={classnames("menu-trigger", { open: isOpen })}
			onClick={toggleMenu}
		>
			Menu
		</div>
	);

	const close = (
		<div
			className={classnames("menu-close", { open: isOpen })}
			onClick={toggleMenu}
		>
			<img className="menu-close__icon" src={icon} alt="close" />
		</div>
	);

	return (
		<div
			className={classnames(
				"container",
				{ open: showElements },
				{ home: isHomepage },
				{ scroll }
			)}
		>
			<MediaQuery query="(min-width: 901px)">
				<Lang className={classnames("lang-wrap", { open: showLang })} />
			</MediaQuery>
			{!isHomepage && (
				<MediaQuery query="(min-width: 901px)">
					<Logo />
				</MediaQuery>
			)}
			{isHomepage && showElements && <Logo />}
			{children}
			<Query.TabletLandscape>
				<TabletNav isOpen={isOpen} toggleMenu={toggleMenu} />
				{menu}
				{close}
				{/* <div className="menu-trigger" onClick={toggleMenu}>
					Menu
				</div> */}
			</Query.TabletLandscape>
			<Query.Desktop>
				<Nav />
			</Query.Desktop>
			{isHomepage && (
				<Query.TabletPortrait down>
					<MobileNav isOpen={isOpen} toggleMenu={toggleMenu} />
					{menu}
					{close}
				</Query.TabletPortrait>
			)}
			{rodo && (
				<Query.TabletPortrait down>
					<TabletNav isOpen={isOpen} toggleMenu={toggleMenu} />
					<Link to="/">
						<div className="menu-close open">
							<img
								className="menu-close__icon"
								src={icon}
								alt="close"
							/>
							<div className="menu-close__line" />
						</div>
					</Link>
				</Query.TabletPortrait>
			)}
		</div>
	);
};

export default Container;
