import React from "react";

export default {
	en: {
		home: {
			letsMeet: "Let's meet"
		},
		about: {
			title: "about",
			subtitle: {
				default: (
					<>
						We are a social media agency
						<br /> specializing in bold communication <br />{" "}
						<span>that grabs attention. </span>
					</>
				),
				tabletPortrait: (
					<>
						We are a social media agency specializing <br /> in bold
						communication <span>that grabs attention. </span>
					</>
				)
			},
			textMiddle: (
				<>
					With the world changing so fast,
					<br />
					we have to run our business <span>even faster.</span>
				</>
			),
			textBottom: (
				<>
					We can help you take part in the race
					<br />
					and <span>win the ultimate price</span> - the audience
					<br />
					that listens and follows your advice.
				</>
			),
			slider: {
				default: "Be the community. Sell your ideas.",
				top: "Be the community.",
				bottom: "Sell your ideas."
			}
		},
		solutions: {
			title: "solutions",
			subtitle: {
				default: (
					<>
						Do you have so many problems
						<br /> that you don't even know
						<br />
						where to start?
					</>
				),
				tabletLandscape: (
					<>
						Do you have so many problems that you don't even know
						where to start?
					</>
				),
				mobile: (
					<>
						Do you have so many problems
						<br /> that you don't even know where to start?
					</>
				)
			},
			line: "We know.",
			textTop: {
				default: (
					<>
						That's why we want to help you solve all of them.
						<br />
						Together, because{" "}
						<span className="solutions-middle__text--bold">
							how you want to be perceived matters.
						</span>
					</>
				),
				mobile: (
					<>
						That's why we want to help you solve all
						<br />
						of them. Together, because
						<span className="solutions-middle__text--bold">
							how you want <br />
							to be perceived matters.
						</span>
					</>
				)
			},
			textMiddle: {
				default: (
					<>
						Let us help you create a great story of your brand that
						increase sells.
					</>
				),
				mobile: (
					<>
						Let us help you create a great story of
						<br /> your brand that increase sells.
					</>
				)
			},
			textBottom: (
				<>
					Social media management
					<br />
					Social media campaigns and ads
					<br />
					Content production
					<br />
					Video & Livestream production
					<br />
					Branding & Web design
					<br />
					Search engine optimization
				</>
			),
			slider: "we love it. we do it*.",
			textAnim: "*and people follow it."
		},
		contact: {
			title: "say hello",
			subtitle: "WORDS HAVE POWER. USE THEM WELL.",
			topic: "topic:",
			message: "how can we help:",
			rodo: "You probably heard it before – read RODO.",
			button: "Send",
			wrongEmail: "wrong email",
			wrongTopic: "please provide a topic",
			wrongMessage: "to short message",
			success: "Your message was sent successfully.",
			error: (
				<>
					We couldn't send your message <br />
					Please try again later.
				</>
			),
			thanks: "THANKS!"
		},
		nav: {
			about: "About us",
			solutions: "Our solutions",
			contact: "Contact"
		}
	},
	pl: {
		home: {
			letsMeet: "Poznajmy się"
		},
		about: {
			title: "to my",
			subtitle: {
				default: (
					<>
						Jesteśmy agencją social media
						<br />
						tworzącą <span>odważną komunikację</span> <br />
						przyciągającą uwagę.
					</>
				),
				tabletPortrait: (
					<>
						Jesteśmy agencją social media tworzącą <br />
						<span>odważną komunikację</span> przyciągającą uwagę.
					</>
				)
			},
			textMiddle: (
				<>
					Świat zmienia się tak szybko,
					<br />
					że musimy myśleć o przyszłości
					<br />
					naszego biznesu <span>jeszcze szybciej.</span>
				</>
			),
			textBottom: (
				<>
					Pomożemy Ci wygrać wyścig o klientów,
					<br />
					którzy będą podążać za Twoimi <span>wskazówkami</span>.
				</>
			),
			slider: {
				default: "Stwórz społeczność i sprzedawaj swoje pomysły.",
				top: "Stwórz społeczność",
				bottom: "i sprzedawaj swoje pomysły."
			}
		},
		solutions: {
			title: "to robimy",
			subtitle: {
				default: (
					<>
						Masz problem z Social Media,
						<br />a nie wiesz od czego zacząć?
					</>
				),
				tabletLandscape: (
					<>
						Masz problem z Social Media, a nie wiesz od czego
						zacząć?
					</>
				),
				mobile: (
					<>
						Masz problem z Social Media,
						<br />a nie wiesz od czego zacząć?
					</>
				)
			},
			line: "Nie martw się.",
			textTop: {
				default: (
					<>
						Przeszliśmy tę drogę i mamy dobre pomysły jak je
						rozwiązać. <br />
						Wiemy, że działając razem{" "}
						<span className="solutions-middle__text--bold">
							osiągniemy sukces.
						</span>
					</>
				),
				mobile: (
					<>
						Przeszliśmy tę drogę i mamy dobre
						<br /> pomysły jak je rozwiązać. Wiemy, że
						<br /> działając razem{" "}
						<span className="solutions-middle__text--bold">
							osiągniemy sukces.
						</span>
					</>
				)
			},
			textMiddle: {
				default: (
					<>
						Pozwól, abyśmy wspólnie napisali świetną historię Twojej
						firmy!
					</>
				),
				mobile: (
					<>
						Pozwól, abyśmy wspólnie napisali
						<br />
						świetną historię Twojej firmy!
					</>
				)
			},
			textBottom: (
				<>
					Tworzenie kampanii Social Media
					<br />
					Zarządzanie Social Media
					<br />
					Reklamy Social Media
					<br />
					Produkcja kontentu
					<br />
					Nagrywanie wideo & live
					<br />
					Projektowanie graficzne - Branding & Web
					<br />
					Pozycjonowanie
					<br />
				</>
			),
			slider: "To właśnie kochamy i to robimy*.",
			textAnim: "*A ludzie to kupują."
		},
		contact: {
			title: "kontakt",
			subtitle: "Słowa mają moc. Użyj ich mądrze.",
			topic: "temat:",
			message: "jak możemy Ci pomóc:",
			rodo: "Pewnie to już znasz – przeczytaj RODO.",
			button: "Wyślij",
			wrongEmail: "zły email",
			wrongTopic: "podaj temat",
			wrongMessage: "zbyt krótka wiadmość",
			success: "Twoja wiadomość została wysłana",
			error: (
				<>
					Błąd przy wysyłaniu wiadomości. <br />
					Spróbuj ponownie poźniej.
				</>
			),
			thanks: "DZIĘKUJE!"
		},
		nav: {
			about: "To my",
			solutions: "To robimy",
			contact: "Kontakt"
		}
	}
};
