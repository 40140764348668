import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Container from "../shared/Container";
import Header from "../shared/Header";
import LangContext from "../langContext";
import dict from "../../data/dict";
import PageAnim from "../shared/PageAnim";


const Contact = () => {
	const { lang } = useContext(LangContext);
	const trans = dict[lang].contact;

	return (
		<Container>
			<PageAnim text="CONTACT" />
			<div className="contact">
				<Header
					name="contact"
					title={trans.title}
					className="contact-header"
				/>
				<div className="contact-content">
					<div className="contact-content__form">
						<h3 className="contact-content__title">
							{trans.subtitle}
						</h3>
						<div className="data-wrap">
							<p className="contact-content__text">
								E: office@problemsolved.social
							</p>
							<p className="contact-content__text">
								P: +353 1 443 3066
							</p>
							<p className="contact-content__text">
								M: +48 699 533 644
							</p>
						</div>
						<Link to="/rodo" className="contact-content__link">
							{trans.rodo}
						</Link>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Contact;
